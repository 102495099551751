.redeem_loyalty_wrapper {
  &.yellow {
    background: #fff5cf;
  }
  &.black {
    background: #f0f0f0;
  }

  .loyalty-program-info {
    display: flex;
    padding: 25px 0 25px 25px;

    &.arabic {
      text-align: right;
      padding: 25px 25px 25px 0;
    }

    .info-right-side {
      width: 100%;
      margin: 0 25px;

      .loyalty-progress-bar {
        width: 100%;
        margin: 30px auto 0 10px;

        .progress-container {
          margin-top: 15px;

          .progress-bar {
            height: 19px;

            .star-icon {
              height: 12px;
              width: 12px;

              &::after {
                top: 17px;
                height: 18px;
              }
            }
          }

          img {
            margin: 7px 10px 10px;
          }
        }

        .additional-info {
          font-size: 14px;
        }

        .collected-points-info {
          p {
            font-size: 16px;
          }
        }
      }

      .redeem-button-section {
        width: 250px;
        margin: 40px auto 0;

        .smile-points {
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
        }
        .min-points {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}

.redeem-loyalty-mobile {
  font-family: Futura PT;
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  position: relative;
  z-index: 0;
  .bar-code {
    text-align: center;
    direction: ltr;
  }
  .benefits-section {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
  }
  .upgrade-tier-section {
    height: 50px;
    margin: 15px 0 0 0;
    background-color: black;
    color: white;
    text-align: center;
    .to-black-tier {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      padding: 4px 0 0 0;
      margin: 0;
    }
    .more-smile-points {
      font-weight: 400;
      font-size: 12px;
      margin: 8px 0 0 0;
    }
    .black-star-icon {
      position: absolute;
      z-index: 1;
      top: 362px;
      left: 15px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      &.arabic {
        right: 15px;
      }
      img {
        width: 20px;
        height: auto;
      }
    }
  }
  .expire-section {
    height: 63px;
    margin: 30px 0 0 0;
    border-radius: 10px;
    background-color: #fffae5;
    border: 1px solid #ffcf01;
  }
  .redeem_btn_mobile {
    background: #ffd100;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    height: 48px;
    border: none;
    margin: 30px 0 30px;
  }
  .min-points {
    font-size: 14px;
    margin-top: -20px;
  }

  .popup-text {
    text-align: initial;
  }
  &.black {
    .details-popup {
      background: #fbfbfb;
      border-color: #dcdcdc;
    }
  }
}

.redeem-expiry-details-block {
  width: auto;

  .details-popup {
    padding: 20px;
    background: #fffae5;
    border: 1px solid #ffcf01;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    margin: 30px 0 0 0;

    .popup-text {
      margin: 0 20px;
      p {
        margin: 0;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1220px) {
  .info-right-side {
    display: flex;
    flex-direction: column;
    .loyalty-redeem-info {
      margin-top: 15px;
      margin-bottom: 15px;
    }
    .progress-bar-with-redeem-btn {
      margin-left: -100%;
      margin-right: 0;
    }
  }
  .info-left-side .loyalty_card {
    transform: scale(0.85, 0.85) translate(10%, 0);
  }
  .redeem_loyalty_wrapper .info-right-side .progress-bar-with-redeem-btn {
    .loyalty-progress-bar {
      margin-left: 0;
    }
  }
  .arabic {
    .info-left-side .loyalty_card {
      transform: scale(0.85, 0.85) translate(-10%, 0);
    }
    .progress-bar-with-redeem-btn {
      margin-left: 0;
      margin-right: -100%;
      .loyalty-progress-bar {
        margin-left: 0;
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .info-right-side {
    .progress-bar-with-redeem-btn {
      margin-left: -100%;
      margin-right: 30%;
    }
  }
  .arabic {
    .progress-bar-with-redeem-btn {
      margin-left: 30%;
      margin-right: -100%;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1080px) {
  .info-right-side {
    .progress-bar-with-redeem-btn {
      margin-left: -150%;
      margin-right: 0;
    }
  }
  .info-left-side .loyalty_card {
    transform: scale(0.85, 0.85) translate(0, 0);
  }
  .arabic {
    .progress-bar-with-redeem-btn {
      margin-left: 0;
      margin-right: -150%;
    }
  }
}

@include mquery(bootMedium) {
  .redeem_loyalty_wrapper {
    .loyalty-program-info {
      padding: 2px;
      padding: 20px 0;
      justify-content: center;
      flex-wrap: wrap;

      .info-right-side {
        margin-top: 25px;

        .loyalty-progress-bar {
          width: 90%;
          margin: auto;
        }
      }
    }
  }
}

@include mquery(mobile) {
  .redeem_loyalty_wrapper {
    .loyalty-program-info {
      &.arabic {
        padding: 25px 0;
      }

      .info-left-side {
        .redeem-expiry-details-block {
          width: 350px;
          margin: 0 auto;
        }
      }

      .info-right-side {
        margin: 0;
      }
    }
  }
}
