@import "@/css/variable";
@import "@/css/mixin";

.bfl-countdown {
  font-size: 24px;
  font-family: FuturaPT-Medium, Helvetica, Arial, sans-serif;
  font-weight: 900;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
  white-space: pre;
  z-index: 2;

  @include mquery(mobile) {
    font-size: 16px;
  }

  &_section {
    position: relative;
    display: inline-block;
  }

  &_box {
    display: inline-block;
  }

  &_wrapper-1,
  &_wrapper-2 {
    .bfl-countdown_text {
      text-transform: uppercase;
      font-size: 12px;
      position: absolute;
      transform: translate(-50%, 0);
      top: 44px;
      left: 38px;
      @include mquery(mobile) {
        font-size: 8px;
        top: 32px;
        left: 28px;
      }
    }

    .colon-dots {
      margin-right: 5px;
      @include mquery(mobile) {
        font-size: 25px;
      }
    }
  }

  &_wrapper-1 {
    .bfl-countdown_section {
      border-radius: 7px;
      width: 60px;
      text-align: center;
      @include mquery(mobile) {
        width: 32px;
        font-size: 13px;
      }
      @include mquery(small) {
        width: 26px;
      }
    }
    .bfl-countdown_box {
      color: $black_color;
      padding: 4px 0;
      border-radius: 4px;
      text-align: center;
      margin: 0 1px;
    }
    .bfl-countdown_text {
      left: 28px;
      @include mquery(mobile) {
        left: 16px;
      }
    }

    .colon-dots {
      font-size: 30px;
      @include mquery(small) {
        margin-right: 4px;
      }
    }
  }

  &_wrapper-2 {
    .bfl-countdown_box {
      padding: 4px;
      min-width: 34px;
      text-align: center;
      margin: 0 1px;
      @include mquery(mobile) {
        min-width: 15px;
      }
      @include mquery(small) {
        margin: 0;
        padding: 4px 3px;
      }
    }
    .bfl-countdown_section {
      @include mquery(mobile) {
        font-size: 13px;
      }
    }
    .colon-dots {
      font-size: 25px;
      @include mquery(small) {
        margin-right: 2px;
      }
    }
    .bfl-countdown_text {
      @include mquery(mobile) {
        left: 16px;
      }
    }
  }
}
