.redeem_option_btn {
  max-width: 237px;
  width: 100%;
  margin-top: 38px;
  margin-bottom: 29px;
  margin-left: auto;
  margin-right: auto !important;
}

.redeem_congratulation {
  text-align: center;
  @include flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    font-family: $font-Futura-Medium;
    font-size: 18px;
    color: $grey84;
    span {
      color: #000;
    }
  }
}

.redeem_option_wrapper {
  .error {
    margin: 4px 0 0 0;
  }
}
