@import "@/css/mixin";
@import "@/css/variable";

.filter_wrapper.sale_filter_wrapper {
  background-color: $white_color;
  z-index: 9;
  &.arabic {
    direction: rtl;
    .filter_types {
      @include mquery(wrap) {
        text-align: right;
      }
    }
  }
  .listing_filter_web_view {
    .filter_types {
      text-align: left;
      margin: 0 -10px 3px -10px;
      padding: 0 14px;
      display: flex;
      flex-wrap: wrap;
      .arabic & {
        direction: rtl;
      }

      h4 {
        display: inline-block;
        vertical-align: middle;
        padding: 11px 12px 11px 7px;
        font-size: 16px;
        color: $secondary_grey_color;
        font-family: $font-Futura-Book;
        position: relative;
        width: 15%;
        margin: 8px 10px 8px;
        cursor: pointer;
        border-top: 1px solid $dar_grey_color;
        border-bottom: 1px solid $dar_grey_color;
        @media (max-width: 1200px) {
          width: 22%;
        }
        @include mquery(bootMedium) {
          width: 30%;
        }
        &:before {
          content: "";
          position: absolute;
          background-color: $dar_grey_color;
          height: 15px;
          width: 1px;
          right: -10px;
          top: 18px;
        }
        &:after {
          content: "";
          position: absolute;
          display: inline-block;
          top: 18px;
          right: 8px;
          background: url("~@/images/small_grey_arrow.svg") no-repeat;
          width: 10px;
          height: 8px;
          transition: all 0.2s;
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
        }
        &.filter_open {
          position: relative;
          &:after {
            content: "";
            position: absolute;
            transform: rotate(-180deg);
          }
        }
        &.size_filter.filter_open {
          border: 2px solid $yellow_color;
          border-bottom: 1px solid transparent;
          & > span.filter_count {
            &::after {
              position: absolute;
              content: "";
              background-color: $white_color;
              width: 100%;
              height: 3px;
              left: 0;
              bottom: -3px;
              z-index: 10;
            }
          }
        }
        &.items_selected {
          border-top: 2px solid $yellow_color;
          color: $black_color;
          &:after {
            background: url("~@/images/small_black_arrow.svg") no-repeat;
          }
          .arabic & {
            direction: rtl;
          }
          .size_chart_wrapper {
            top: 44px;
          }
        }
        .arabic & {
          text-align: right;
          &:after {
            right: auto;
            left: 8px;
          }
        }
        .sizes_groups span {
          font-size: 18px;
        }
        .sizes_formats span {
          font-weight: 600;
        }
        span {
          font-size: 16px;

          > strong {
            display: inline;
            margin-bottom: 0;
            font-size: 16px;
            color: $secondary_grey_color;
            font-family: $font-Futura-Book !important;
            font-weight: 400 !important;
          }
        }

        .filter_content {
          position: absolute;
          background-color: $white_color;
          width: 340px;
          z-index: 5;
          border: 1px solid $greyE5;
          @include box-shadow(0px 12px 24px 0px rgba($mine-shaft-2b, 0.15));
          top: 48px;
          left: -3px;
          cursor: default;
          padding-bottom: 8px;

          &:before {
            content: "";
            position: absolute;
            width: 0;
            border-right: 6px solid transparent;
            border-left: 6px solid transparent;
            border-bottom: 5px solid #fff;
            top: -5px;
            left: 8px;
            z-index: 2;
          }
          &:after {
            content: "";
            position: absolute;
            width: 0;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
            border-bottom: 6px solid rgb(229, 229, 229);
            top: -6px;
            left: 7px;
            z-index: 1;
          }

          .arabic & {
            left: auto;
            right: -3px;
            direction: ltr;
            &:before {
              left: auto;
              right: 8px;
            }
            &:after {
              left: auto;
              right: 7px;
            }
          }

          .brand-search-wrapper {
            position: sticky;
            top: 0;
            background-color: $dar_grey_color;
            display: flex;
            justify-content: center;
            padding: 7px;
            z-index: 10;

            input {
              width: 100%;
              height: 47px;
              text-align: center;
              line-height: normal;
              outline: none;

              &::placeholder {
                font-size: 14px;
                text-align: center;
              }
            }

            .search_icon {
              background: url("~@/images/search-icon.svg") no-repeat;
              background-size: contain;
              width: 14px;
              height: 14px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 20px;
            }
          }

          ul {
            position: relative;
            padding: 10px 10px 5px 25px;
            height: 100%;

            .arabic & {
              padding: 10px 25px 5px 10px;
            }
            li {
              cursor: default;
              padding: 8px 0;
              .arabic & {
                direction: rtl;
              }

              &.right_check_label {
                label {
                  .filterSelectedContent {
                    display: flex;
                    align-items: center;
                    width: 90%;
                    .filterOptionIconWrapper {
                      max-height: 30px;
                      max-width: 30px;
                      .store_img {
                        width: 100%;
                        height: 100%;
                      }
                    }
                  }
                  span {
                    &:first-child {
                      .arabic & {
                        padding-left: 0;
                        padding-right: 12px;
                      }
                    }
                  }
                }
                .check_circle,
                .check_circle_radio {
                  width: 20px;
                  height: 20px;
                  border: 1px solid $greydf;
                  border-radius: 50%;
                  background-color: $white_color;
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  z-index: 1;
                  padding-right: 0;
                  input[type="checkbox"] {
                    width: 20px;
                    height: 20px;
                    opacity: 0;
                    cursor: pointer;
                    z-index: 0;
                    .arabic & {
                      margin-left: -12px;
                    }
                  }
                }
                &.checked {
                  span {
                    color: $black_color;
                  }
                  .check_circle {
                    background-color: $yellow_color;
                    padding: 0;
                    border: 1px solid $yellow_color;
                    &:after {
                      content: "";
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                      margin: auto;
                      width: 8px;
                      height: 6px;
                      @include sprite_image;
                      background-position: -212px -42px;
                      z-index: -1;
                    }
                  }
                  .check_circle_radio {
                    border: 1px solid $supernova;
                    border-radius: 100%;
                    background-image: linear-gradient(
                      90deg,
                      $orange-peel 0%,
                      $yellow_color 100%
                    );
                    background-image: -moz-linear-gradient(
                      90deg,
                      $orange-peel 0%,
                      $yellow_color 100%
                    );
                    background-image: -webkit-linear-gradient(
                      90deg,
                      $orange-peel 0%,
                      $yellow_color 100%
                    );
                    background-image: -ms-linear-gradient(
                      90deg,
                      $orange-peel 0%,
                      $yellow_color 100%
                    );
                    &:after {
                      content: "";
                      position: absolute;
                      width: 10px;
                      height: 10px;
                      background-color: $white_color;
                      top: 4px;
                      left: 4px;
                      border-radius: 50%;
                    }
                  }
                }
              }

              label {
                display: flex;
                cursor: pointer;
                span {
                  font-size: 16px;
                  color: $secondary_grey_color;
                  font-family: $font-Futura-Book;
                  width: 89%;
                  vertical-align: middle;
                  padding-right: 12px;
                  &.color {
                    border: 1px solid $black_color;
                    $size: 20px;
                    width: $size;
                    height: $size;
                  }
                  .arabic & {
                    padding-right: 0;
                    padding-left: 12px;
                    direction: rtl;
                  }
                }
                span.check_circle {
                  float: right;
                  margin-top: 3px;
                  .arabic & {
                    float: left;
                  }
                }
              }

              &.category_extra_label {
                cursor: pointer;
              }
            }
            &.hierarchical_categories_item {
              padding-right: 26px;
              padding-bottom: 10px;
              padding-top: 5px;
              .arabic & {
                padding-right: 25px;
              }
              &.forward_arrow {
                padding-bottom: 10px;
                padding-top: 20px;
                li {
                  position: relative;
                  &:after {
                    content: "";
                    position: absolute;
                    @include sprite_image;
                    width: 8px;
                    height: 13px;
                    background-position: -136px -128.5px;
                    top: 12px;
                    right: 0;
                    .arabic & {
                      left: 0;
                      right: auto;
                      background-position: -149px -127px;
                      top: 11px;
                    }
                  }
                }
              }
              &.first_arrow {
                padding-top: 10px;
                li {
                  &:first-child {
                    padding-top: 12px;
                    &:after {
                      top: 18px;
                    }
                  }
                }
              }
              li {
                position: relative;
                cursor: pointer;
                &:first-child {
                  padding-top: 0;
                  &:after {
                    top: 5px;
                    .arabic & {
                      top: 4px;
                    }
                  }
                }
                span {
                  width: calc(100% - 22px);
                  cursor: pointer;
                  transition: color 0.3s;
                  display: inline-block;
                  vertical-align: top;
                  &:hover {
                    color: $black_color;
                  }
                }

                .option {
                  width: 20px;
                  padding: 0;
                  label {
                    margin: 0;
                    width: 20px;
                    &:before {
                      top: -15px;
                      border: 1px solid $mercurye7;
                    }
                    &:after {
                      top: -10px;
                    }
                  }
                  &.selected_option {
                    label {
                      &:before {
                        border: 1px solid $supernova;
                      }
                    }
                  }
                }
              }
            }
          }
          &.size_chart_wrapper {
            min-width: 300px;
            max-width: 600px;
            width: auto;
            border: 2px solid $yellow_color;
            left: -2px;
            top: 41px;
            box-shadow: none;
            z-index: 5;
            @include mquery(wrap) {
              min-width: 800px;
            }
            &.openLeft {
              left: auto;
              right: -2px;
            }
            &.openRight {
              left: -2px;
              right: auto;
            }
            &:before {
              display: none;
            }
            &:after {
              display: none;
            }
            .size_chart {
              padding: 0;
              .viewItems {
                height: 37px;
                padding: 0;
                line-height: 33px;
                width: calc(100% - 10px);
                margin: 8px 4px;
                text-transform: uppercase;
                font-weight: 900;
                font-size: 16px;
                letter-spacing: 1px;
                z-index: 999999;
                position: fixed;
              }
              .size_category_wrapper {
                padding: 0;
              }
            }
            .arabic & {
              right: -2px;
            }
          }
          .count_selected {
            font-size: 16px;
            color: $secondary_grey_color;
            margin-top: 10px;
            padding: 0 20px 20px 25px;
            .arabic & {
              direction: rtl;
            }
            span {
              float: right;
              padding: 5px 30px 4px 8px;
              border: 1px solid $greyE5;
              border-radius: 15px;
              position: relative;
              line-height: 16px;
              width: 85px;
              cursor: pointer;
              .arabic & {
                float: left;
                margin-top: -2px;
                padding-right: 8px;
                padding-left: 30px;
              }
              &:after {
                content: "";
                position: absolute;
                @include sprite_image;
                background-position: -230px -39.5px;
                width: 10px;
                height: 10px;
                top: 8px;
                right: 8px;
                .arabic & {
                  right: auto;
                  left: 8px;
                  top: 7px;
                }
              }
            }
          }
          &.open_filter_to_other_side {
            position: absolute;
            right: -2px;
            left: auto;
            &:before {
              content: "";
              position: absolute;
              top: -5px;
              right: 8px;
              left: auto;
            }
            &:after {
              content: "";
              position: absolute;
              top: -6px;
              right: 7px;
              left: auto;
            }
            .arabic & {
              right: auto;
              left: -2px;

              &:before {
                content: "";
                position: absolute;
                top: -5px;
                left: 8px;
              }
              &:after {
                content: "";
                position: absolute;
                top: -6px;
                left: 7px;
              }
            }
          }
        }
      }
    }
  }
}

.mobile_filter_list_head {
  display: none;
}

.m-filter_footer {
  display: none;
}

.listing_filter_web_view {
  .filter_open {
    .filter_content {
      ul {
        padding: 10px 18px 0;
      }
    }
    .categories {
      .category_name {
        font-family: $font-Futura-Book;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        .expendable_icon {
          height: 1rem;
          width: 1rem;
          border: 1px solid $black_color;
          margin-right: 8px;
          .arabic & {
            margin-right: 0;
            margin-left: 8px;
          }
        }
        .expendable_icon {
          position: relative;
          &::before,
          &::after {
            content: "";
            position: absolute;
            display: block;
            height: 100%;
            background-color: $tundora4c;
            border-radius: 5px;
          }
          $plus_minus_size: 70%;
          &::before {
            width: $plus_minus_size;
            height: 1.5px;
            top: 50%;
            left: calc((100% - #{$plus_minus_size}) / 2);
            transform: translateY(-50%);
          }
          &::after {
            width: 1.5px;
            height: $plus_minus_size;
            left: 50%;
            top: calc((100% - #{$plus_minus_size}) / 2);
            transform: translateX(-50%);
          }
        }
        &.expanded {
          .expendable_icon {
            &::after {
              display: none;
            }
          }
        }
      }
      .selected {
        font-weight: bold;
      }
      .filter_item_web {
        label {
          margin: 0;
        }
      }
    }
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .range_container {
    position: relative;
    padding: 10px 18px 5px 25px;
    height: 100%;
    .range_dragger_container {
      padding: 10px 0;
      height: 100%;
      @media only screen and (max-width: 812px) {
        span {
          font-size: 16px;
        }
      }

      p {
        color: $black_color;
        font-family: $font-Futura-Medium;

        span {
          font-size: 16px;
          color: $secondary_grey_color;
          margin-right: 20px;
        }
      }

      .range_dragger {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 28px;
        margin-bottom: 14px;
      }

      @media only screen and (max-width: 767px) {
        .range_dragger_container .range_dragger {
          height: 25%;
        }
      }

      @media only screen and (max-width: 812px) {
        .range_dragger_wrap .range_dragger .input-range__track--background {
          width: 96%;
        }
      }

      .range_dragger {
        .input-range {
          height: 2px;
        }

        .input-range__label--value {
          display: none;
        }

        .input-range__track {
          height: 2px;
        }

        .input-range__slider {
          background-color: $yellow_color;
          border: 1px solid $yellow_color;
          width: 20px;
          height: 20px;
        }

        .input-range__track--background {
          margin-top: 0;
        }

        .input-range__track--active {
          background-color: $yellow_color;
        }
      }

      .input-range__label--min,
      .input-range__label--max {
        display: none;
      }
    }
  }

  .size_filter {
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    &.filter_open {
      > span {
        &::after {
          content: "";
          background-color: $white_color;
          bottom: -3px;
          content: "";
          height: 3px;
          left: 0;
          position: absolute;
          width: 100%;
          z-index: 6;
        }
      }
    }
  }

  .size_chart_wrapper {
    padding: 12px 25px 30px 25px;
    max-width: 583px;
    @include mquery(wrap) {
      min-width: 800px;
    }
  }

  .sizes_groups {
    display: flex;
    position: relative;
    .swiper {
      margin-left: 0;
      margin-right: 0;
    }
    .swiper-button-prev {
      position: absolute;
      left: -22px;
      width: 16px;
      height: 30px;
      background-color: $wildSand;
      border: none;
      border-radius: 0;
      padding: 0;
      top: calc(50% + 8px);
      &::before {
        display: none;
      }
      &:after {
        content: "";
        position: absolute;
        @include sprite_image;
        width: 8px;
        height: 17px;
        top: 7px;
        left: 4px;
        background-position: -73.5px -194.5px;
      }
    }
    .swiper-button-next {
      right: -22px;
      position: absolute;
      width: 16px;
      height: 30px;
      background-color: $wildSand;
      border: none;
      border-radius: 0;
      padding: 0;
      top: calc(50% + 8px);
      &::before {
        display: none;
      }
      &:after {
        content: "";
        position: absolute;
        @include sprite_image;
        width: 8px;
        height: 17px;
        top: 7px;
        left: 6px;
        background-position: -73.5px -174.5px;
      }
    }
    .level_item {
      width: auto;
      background-color: $grey-ef;
      color: $black_color;
      cursor: pointer;
      display: inline-block;
      font-family: $font-Futura-Medium;
      font-size: 18px;
      overflow: hidden;
      padding: 8px 15px 7px;
      position: relative;
      text-align: center;
      text-overflow: ellipsis;
      vertical-align: top;
      white-space: nowrap;
      &:hover,
      &.active {
        background-color: $black_color;
        color: $white_color;
      }
    }
  }

  .sizes_styles {
    .swiper {
      margin-left: 0;
      margin-right: 0;
    }
    border-bottom: 1px solid rgba($black_color, 0.24);
    display: flex;
    position: relative;
    width: 100%;
    .level_item {
      width: auto;
      color: $grey-80;
      cursor: pointer;
      display: inline-block;
      font-family: $font-Futura-Medium;
      font-size: 16px;
      line-height: 23px;
      margin-right: 10px;
      overflow: hidden;
      padding-bottom: 3px;
      padding-right: 10px;
      padding-top: 15px;
      position: relative;
      text-overflow: ellipsis;
      transition: color 0.3s;
      vertical-align: top;
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }
      &:hover,
      &.active {
        color: $black_08;
        &:after {
          border-bottom: 2px solid $black_08;
          bottom: -1px;
          content: "";
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba($black_color, 0.5);
    box-shadow: 0 0 1px rgba($white_color, 0.5);
  }
  .sizes_formats_scrollbar > div:first-child {
    overflow: auto !important;
    margin-right: 0 !important;
    .arabic & {
      direction: rtl;
    }
  }

  .sizes_formats_scrollbar.windows_scrollbar > div:first-child {
    overflow: scroll !important;
  }

  .sizes_formats {
    margin-top: 1rem;
    max-height: 350px;
  }

  .sizes_items {
    display: flex;
    margin-top: 0.8rem;
    flex-wrap: wrap;
    .level_item {
      color: $black111;
      font-size: 16px;
      height: 26px;
      line-height: 25px;
      margin-bottom: 15px;
      margin-right: 11px;
      width: auto;
      min-width: 34px;
      padding: 0 6px;
      border: 1px solid $color-e6;
      cursor: pointer;
      text-align: center;
      background-color: rgba($white_color, 0.9);
      transition: background-color 0.3s, color 0.3s;
      &:hover,
      &.active {
        background-color: $black_color;
        border: 1px solid $black_color;
        color: $white_color;
      }
    }
  }

  .arabic & {
    .sizes_items {
      justify-content: end;
      .level_item {
        margin-right: 0px;
        margin-left: 11px;
      }
    }

    .sizes_styles {
      .level_item {
        margin-right: 0px;
      }
    }
  }

  .filter_item_web {
    .color {
      border: 1px solid $black_color;
      $size: 20px;
      width: $size;
      height: $size;
    }
    .arabic & {
      direction: rtl;
    }
  }
}
