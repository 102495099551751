$grey_color: #f9f9f9;
$dark_grey_color: #dddddd;
$border_light_grey: #e9e9e9;
$secondary_grey_color: #787878;
$yellow_color: #f8d000;
$secondary_yellow_color: #fff1a7;
$yellow_fd100: #ffd100;
$black_color: #000000;
$secondary_black_color: #101010;
$black_08: #080808;
$white_color: #ffffff;
$red_color: #f61a1a;
$dar_grey_color: #eaeaea;
$font-grey: #222222;
$grey-bg: #f4f4f4;
$grey69: #696969;
$grey70: #707070;
$grey84: #848484;
$grey78: #787878;
$grey81: #818181;
$grey_a9: #a9a9a9;
$grey_5b: #5b5b5b;
$grey-light: #e9ecef;
$greyE5: #e5e5e5;
$grey3f: #3f3f3f;
$grey76: #767676;
$greyf7: #f7f7fa;
$light-grey: #eee;
$sky-blue: #098ff6;
$green_color: #00a651;
$limeGreen: #57b80c;
$grey-cb: #cbcbcb;
$grey-de: #dedede;
$color4a4a: #4a4a4a;
$grey-f0: #f0f0f0;
$grey-f6: #f6f6f6;
$darkGrey-4d: #4d4d4d;
$darkGrey-15: #151515;
$dark-0f: #0f0f0f;
$lightGrey-59: #595858;
$black111: #111111;
$black6565: #656565;
$lightGrey-f2f2: #f2f2f2;
$steelGray: #4e4d4d;
$slateGray: #4f4e4e;
$stoneGray: #7b7a7a;
$grey-transparent: #f7f7fa8c;
$color-ccc: #cccccc;
$standard_font_size: 14px;
$color-bf: #bfbfbf;
$color-e6: #e6e6e6;
$color-bb: #bbb;
$color-20: #202020;

$orange-fed100: #fed100;
$blue-azure-radiance: #0080ff;
$azureRadianceaff: #008aff;
$grey-ef: #efefef;
$grey-9f: #9f9f9f;
$color-d7: #d7d7d7;
$gold-color: #c3a25f;
$lighter-red: #e03725;
$very-light-red: #fff4f4;
$grey-80: #808080;
$grey-b4: #b4b4b4;
$grey-f8: #f8f8f8;
$light-yellow: #fffbe9;
$light-yellow-background: #fffdf3;
$yellow-white: #fffdf5;
$light-black: #1c1c1c;
$input-white: #d5d5d5;
$arrow-color: #000;
$bottled-sea: #0655b1;
$discount-red: #df0000;
$flash-red: #ff0000;
$greyb2: #b2b2b2;
$grey6d: #6d6c6c;
$black02: #020202;
$green08: #08970f;
$green57: #57b80b;
$green09B: #09b800;
$greyc2: #c2c2c2;
$greyec: #ececec;
$greyb5: #b5b5b5;
$orangede: #de580d;
$greya1: #a1a1a1;
$black24: #242424;
$black0d: #0d0d0d;
$black21: #212121;
$black2a: #2a2a2a;
$black01: #010101;
$green17: #17904d;
$whitee4: #e4e4e4;
$reddc: #dc3545;
$grey94: #949494;
$grey66: #666666;
$grey6a: #6a6a6a;
$grey47: #474747;
$orangef4: #f4d400;
$orangeff: #ffd101;
$redf4: #f41010;
$redb7: #bf1717;
$black27: #272727;
$whitef7: #f7f7f7;
$yellowff: #fff2be;
$grey46: #464646;
$grey51: #515151;
$grey9c: #9c9c9c;
$black25: #252525;
$greyb9: #b9b9b9;
$redda: #da2626;
$greydf: #dfdfdf;
$greycd: #cdcdcd;
$black03: #030303;
$black1f: #1f1f1f;
$black19: #191919;
$redf4: #f4100e;
$reded: #ed3a3a;
$redcd: #cd0101;
$fireEngineRed: #ec0404;
$greydc: #dcdcdc;
$greyed: #ededed;
$black49: #494949;
$green02: #022222;
$green00: #008e28;
$greene5: #e5ffed;
$black28: #282828;
$whitedc: #dcdbe0;
$green5c: #5cb25f;
$grey9d: #9d9d9d;
$grey5d: #5d5d5d;
$greyd1: #d1d1d1;
$grey64: #646464;
$tundora: #403f3f;
$tundora4c: #4c4c4c;
$tundora45: #464545;
$tundora42: #424242;
$tundora40: #404040;
$boulder_solid: #7a7a7a;
$boulder79: #797979;
$boulder777: #777777;
$mine-shaft-2b: #2b2b2b;
$mine-shaft-33: #333333;
$supernova: #f8c500;
$orange-peel: #f89e00;
$mercurye7: #e7e7e7;
$mercurye8: #e8e8e8;
$silverbd: #bdbdbd;
$silverbe: #bebebe;
$silverc5: #c5c5c5;
$silverc1: #c1c1c1;
$silverc4: #c4c4c4;
$thunder: #231f20;
$torch-red: #f21a1a;
$crimson: #eb1515;
$doveGray6d: #6d6d6d;
$doveGray72: #727272;
$scorpion: #606060;
$greye8: #e8e9e9;
$blue09: #096ec0;
$greyda: #dadada;
$chablis: #fff5f5;
$cod_gray: #090909;
$japaneseLaurel: #338203;
$silverChalice: #acacac;
$ginFizz: #fffae4;
$alabaster: #fafafa;
$mineShaft: #3b3b3b;
$wildSand: #f5f5f5;
$alto: #dbdbdb;
$altoe0: #e0e0e0;
$dustyGray: #999999;
$codGray: #1e1e1e;
$emperor: #525252;
$grey2f: #2f2f2f;
$forestGreen: #337a1d;
$yellowfadb: #fadb00;
$yellowTurbo: #fee101;
$flory: #f56d6d;
$trout: #495057;
$lunarGreen: #414241;
$blackWhite: #fffef9;
$seashellSolid: #f1f1f1;
$black26: #262626;

$greyd0: #d0d0d0;
$yellowf8d: #f8d000;
$arrow-size: 14px;
$arrow-border: 2px;

$font-Futura: "FuturaPT", Arial, sans-serif;
$font-base: "FuturaPT-Demi", Helvetica, Arial, sans-serif;
$font-Futura-Book: "Futura PT Book", Helvetica, Arial, sans-serif;
$font-Futura-Light: "FuturaPT-Light", Helvetica, Arial, sans-serif;
$font-Futura-Medium: "FuturaPT-Medium", Helvetica, Arial, sans-serif;
$font-Futura-PT: "Futura PT", Helvetica, Arial, sans-serif;

$width-of-liveButton: 340px;
