@import "@/css/variable";
@import "@/css/mixin";

.help_container {
  padding: 30px 0;
  @include mquery(mobile) {
    padding: 25px 14px;
  }
  &.arabic {
    .category_section {
      ul {
        li {
          padding-left: 0;
          padding-right: 20px;
          img {
            float: right;
          }
          h5 {
            text-align: right;
            padding-right: 15px;
            padding-left: 0;
            img {
              float: left;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    .help_cards {
      direction: rtl;
      .card_details {
        direction: rtl;
        span {
          img {
            padding-right: 0;
            padding-left: 6px;
            transform: rotate(180deg);
          }
        }
      }
    }
    .help_options {
      direction: rtl;
      div {
        padding-right: 15px;
        margin-right: 0;
        h6 {
          width: calc(100% - 25px);
        }
      }
    }
    .faq_section {
      .accordion {
        .accordion_content {
          direction: rtl;
          text-align: right;
          padding-right: 0;
          padding-left: 30px;
          margin-right: 22px;
          li {
            direction: rtl;
            margin-right: 18px;
          }
        }
        .accordion_heading {
          text-align: right;
          width: calc(100% - 19px);
          padding-left: 20px;
        }
      }
      .toggle-banner {
        float: left !important;
      }
    }
    .qa_container {
      direction: rtl;
      .faq_section {
        h4 {
          text-align: right;
        }
      }
      .topics_section {
        text-align: right;
        padding-right: 39px;
        padding-left: 0;
        @include mquery(tablet) {
          width: 100%;
        }
        ul {
          li {
            //padding-right: 15px;
            padding-left: 0;
            h5 {
              img {
                float: left;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
  .header {
    h4 {
      font-family: $font-base;
      font-size: 20px;
      text-align: center;
      color: #898989;
      @include mquery(tablet) {
        font-size: 16px;
      }
    }
    p {
      font-family: $font-base;
      font-size: 32px;
      text-align: center;
      color: #000000;
      @include mquery(tablet) {
        font-size: 18px;
        margin-bottom: 0;
      }
    }
  }
  .help_cards {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    margin-left: -8px;
    margin-right: -8px;
    div {
      width: calc(33.3% - 16px);
      max-height: 213px;
      min-height: 213px;
      margin-right: 8px;
      margin-bottom: 16px;
      margin-left: 8px;
      opacity: 0.9;
      border: solid 1px #e6e6e6;
      background-color: $whitef7;
      text-align: center;
      padding-top: 58px;
      padding-bottom: 58px;
      span {
        h4 {
          font-size: 20px;
          color: #000000;
          font-family: $font-base;
          margin-top: 13px;
        }
      }
      .card_details {
        display: none;
        h4 {
          font-size: 20px;
          font-family: $font-base;
          color: #000000;
        }
        p {
          font-size: 16px;
          font-family: $font-Futura-Medium;
          color: #000000;
          margin-bottom: 8px;
          max-width: 75%;
          margin-right: auto;
          margin-left: auto;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        span {
          font-family: $font-base;
          font-size: 14px;
          color: #000000;
          cursor: pointer;
          h6 {
            font-size: 14px;
            font-family: $font-base;
            color: #000000;
            display: inline-block;
            margin-bottom: 0;
            margin-top: 9.77px;
          }
          img {
            padding-left: 6px;
            padding-bottom: 2px;
          }
        }
      }
      &:hover {
        background-color: #fff;
        padding: 22px 45px;
        h4 {
          margin-top: 0;
          margin-bottom: 20px;
          border-bottom: 1px solid $yellow_color;
          padding-bottom: 10px;
        }
        .card_details {
          display: block;
        }
        .card_name {
          display: none;
        }
      }
    }
    @include mquery(tablet) {
      display: none;
    }
  }
  .faq_section {
    background-color: #f0f0f0;
    padding: 0 28px 18px 28px;
    margin-top: 14px;
    @include mquery(mobile) {
      padding: 0 16px 18px 16px;
    }
    @include mquery(small) {
      margin-left: -14px;
      margin-right: -14px;
      width: calc(100% + 28px);
    }
    h4 {
      text-align: center;
      font-size: 24px;
      font-family: $font-base;
      color: #000000;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .accordion {
      background-color: #fff;
      border-radius: 5px;
      padding: 0;
      margin-bottom: 10px;
      .accordion_heading {
        font-size: 20px;
        color: $black_color;
        display: inline-block;
        margin-bottom: 0;
        width: calc(100% - 30px);
        padding: 0;
        text-align: left;
        font-family: $font-base;
        word-break: break-word;
        @include mquery(tablet) {
          font-size: 17px;
          line-height: 1.2;
        }
      }
      .accordion_content {
        font-size: 18px;
        font-family: $font-Futura-Medium;
        color: #868686;
        padding: 0 30px 21px 20px;
        @include mquery(tablet) {
          font-size: 16px;
        }
        @include mquery(mobile) {
          padding: 0 12px 8px;
        }
        p {
          font-family: $font-Futura-Book;
          color: $grey84;
          @include mquery(tablet) {
            font-size: 16px;
          }
        }
      }
      .accordion_card_title {
        padding: 21px 21px 20px;
        cursor: pointer;
        @include mquery(mobile) {
          padding: 9px 15px 9px 15px;
        }
      }
      .toggle-banner {
        float: right;
        font-size: 32px;
        cursor: pointer;
        height: 21px;
        display: inline-block;
        vertical-align: top;
        background-position: center center !important;
        @include mquery(mobile) {
          font-size: 22px;
        }
      }
      .plus {
        background: url("../../images/icon-plus-black.svg") no-repeat;
        width: 19px;
      }
      .minus {
        background: url("../../images/icon-minus-black.svg") no-repeat;
        width: 19px;
      }
    }
  }

  .qa_container {
    @include flex;
    flex-wrap: wrap;
    .faq_section {
      width: 69%;
      @include mquery(tablet) {
        width: 100%;
        margin-bottom: 0;
      }
      @include mquery(small) {
        width: calc(100% + 28px);
        margin-left: -14px;
        margin-right: -14px;
        margin-bottom: 0;
      }
      h4 {
        text-align: left;
        padding-top: 27px;
        @include mquery(mobile) {
          font-size: 18px;
          padding-bottom: 12px;
          padding-top: 12px;
        }
      }
    }
    .topics_section {
      width: 31%;
      font-size: 24px;
      font-family: $font-base;
      color: #000000;
      padding-left: 39px;
      padding-top: 40px;
      @include mquery(tablet) {
        display: none;
      }
      h4 {
        padding-bottom: 2px !important;
      }
      ul {
        padding-left: 0;
        li {
          list-style: none;
          padding-bottom: 10px;
          padding-top: 18px;
          padding-left: 0;
          border-bottom: 1px solid #d6d6d6;
          h5 {
            font-size: 18px;
            font-family: $font-Futura-Medium;
            color: #000000;
            cursor: pointer;
            margin-bottom: 0;
            img {
              float: right;
              padding-right: 5px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .category_section {
      width: 100%;
      font-size: 24px;
      font-family: $font-base;
      color: #000000;
      padding-top: 40px;
      @include mquery(mobile) {
        padding-top: 25px;
      }
    }
  }
  .category_section {
    width: 100%;
    font-size: 24px;
    font-family: $font-base;
    color: #000000;
    padding-top: 40px;
    display: none;
    @include mquery(tablet) {
      display: block;
    }
    @include mquery(mobile) {
      padding-top: 10px;
    }
    h4 {
      padding-bottom: 25px !important;
      @include mquery(tablet) {
        padding-left: 20px;
      }
      @include mquery(mobile) {
        padding-bottom: 0 !important;
        text-align: center;
        padding-left: 0;
      }
    }
    ul {
      padding-left: 0;
      li {
        padding-bottom: 10px;
        list-style: none;
        padding-top: 18px;
        padding-left: 15px;
        border-bottom: 1px solid #d6d6d6;
        img {
          @include mquery(large) {
            width: 38px;
            height: 30px;
          }
          width: 30px;
          height: 22px;
        }
        h5 {
          @include mquery(large) {
            font-size: 18px;
          }
          font-size: 14px;
          font-family: $font-Futura-Medium;
          color: #000000;
          cursor: pointer;
          margin-bottom: 0;
          display: inline-block;
          width: calc(100% - 49px);
          padding-left: 20px;
          img {
            float: right;
            padding-right: 5px;
            cursor: pointer;
            height: auto;
            width: initial;
          }
        }
      }
    }
  }
}

.help_text {
  margin-top: 30px;
  div {
    text-align: center;
    margin-bottom: 19px;
    p {
      font-size: 20px;
      color: #898989;
      font-family: $font-base;
      margin-bottom: 7px;
      @include mquery(tablet) {
        font-size: 18px;
      }
    }
    h4 {
      font-size: 32px;
      color: #000000;
      font-family: $font-base;
      @include mquery(tablet) {
        font-size: 24px;
      }
    }
  }
  .rtl {
    direction: rtl;
  }
  .help_options {
    @include flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 888px;
    @include mquery(tablet) {
      padding-left: 20px;
      padding-right: 10px;
      max-width: 775px;
    }
    @include mquery(mobile) {
      padding-left: 10px;
      padding-right: 0;
    }
    a,
    button {
      width: 24%;
      margin-right: 9px;
      @include mquery(tillTabLandscape) {
        width: 32%;
      }
      @include mquery(mobile) {
        flex: 0 0 100%;
      }
      &:hover {
        text-decoration: none;
      }
      &:first-child {
        width: 100%;
        .chat-img {
          width: 24% !important;
          margin-left: auto;
          margin-right: auto;
          border: 2px solid $black_color;
          @include mquery(tillTabLandscape) {
            width: 32% !important;
          }
          @include mquery(mobile) {
            width: 100% !important;
          }
        }
      }
    }
    button {
      &:first-child {
        border: none;
        background: none;
      }
    }
    div {
      @include flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-top: 9px;
      padding-bottom: 9px;
      cursor: pointer;
      border: 2px solid $black_color;
      > span {
        @include social_sprite;
      }
      &.chat-img {
        span {
          background: url("../../images/chat.svg") no-repeat left center;
          width: 24px;
          height: 20px;
        }
        &:hover {
          span {
            background: url("../../images/chat-hover.svg") no-repeat left center;
            width: 24px;
            height: 20px;
          }
          h6 {
            color: $yellow_color;
          }
        }
      }
      &.message-img {
        span {
          background-position: -3.5px -4px;
          width: 23px;
          height: 24px;
        }
      }
      &.messenger-img {
        span {
          background-position: -38px -4px;
          width: 24px;
          height: 24px;
        }
      }
      &.facebook-img {
        span {
          background-position: -72px -4px;
          width: 24px;
          height: 24px;
        }
      }
      h6 {
        padding-left: 8px;
        margin-bottom: 0;
        position: relative;
        display: inline-block;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: auto;
        }
      }
    }
  }
  .support_details {
    margin-top: 10px;
    margin-bottom: 0;
    a {
      display: inline-block;
      &:hover {
        text-decoration: none;
      }
    }
    span {
      font-size: 24px;
      font-family: $font-base;
      color: #000000;
      display: block;
      padding-top: 9px;
      @include mquery(mobile) {
        font-size: 20px;
      }
    }
    p {
      font-size: 14px;
      font-family: $font-Futura-Medium;
      color: #898989;
      @include mquery(mobile) {
        font-size: 12px;
      }
    }
  }
}
