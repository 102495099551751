@import "@/css/variable";

.disabled-link {
  pointer-events: none;
}

.banner-element {
  width: 100%;

  .lazy-load-wrapper {
    position: absolute;
    width: 100%;
  }

  .banner-child {
    position: relative;
    background: $greyf7;

    .loader-icon {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
