@import "@/css/variable";
@import "@/css/mixin";

.loyalty_wrap {
  padding: 10px 19px;
  border: 1px dashed $font-grey;
  background-color: #fffdf3;
  margin: 0 13px;
  margin-top: 20px;
  @include mquery(tablet) {
    padding: 10px 20px;
    background-color: #fbf3bf;
    margin: 14px 10px;
    border: 1px dashed $black_color;
  }
  p {
    font-size: 14px;
    color: $font-grey;
    margin-bottom: 0;
    text-align: center;
    span {
      font-family: $font-Futura-PT;
      font-weight: 600;
    }
    a {
      cursor: pointer;
      color: $sky-blue;
      font-weight: bold;
    }
  }
  .learn-more-text,
  .loyalty-join {
    margin: 0 5px;
    cursor: pointer;
    font-family: $font-Futura-Book;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    white-space: nowrap;
    color: $black_color;

    .extra_space {
      display: none;
    }
    .arabic & {
      .extra_space {
        display: inline;
      }
    }
  }
  .loyalty-join {
    text-decoration: none;
    margin-right: 5px;
    font-weight: bold;
  }

  .arabic & {
    .loyalty-join {
      margin-right: 0;
      margin-left: 5px;
    }
  }
}

.quantity_unavailable {
  color: red;
  padding: 8px 20px;
  margin-top: 20px;
  text-align: center;
}

.loyalty_sign_in:hover {
  cursor: pointer;
}
