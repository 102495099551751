@import "@/css/variable";
@import "@/css/mixin";

.loyalty_stats {
  margin-bottom: 16px;
  width: 100%;
  ul {
    @include flex;
    border: 1px solid $border_light_grey;
    @include mquery(tablet) {
      flex-wrap: wrap;
    }
    .arabic & {
      text-align: right;
    }
    li {
      padding-left: 42px;
      padding-right: 25px;
      position: relative;
      width: 25%;
      min-height: 113px;
      @include flex;
      flex-direction: column;
      justify-content: center;
      background-color: $grey_color;
      @include mquery(large) {
        padding-left: 35px;
      }
      @include mquery(tablet) {
        width: 50%;
        &:nth-child(even) {
          &:after {
            display: none;
          }
        }
      }
      @include mquery(mobile) {
        padding-left: 22px;
        padding-right: 20px;
        min-height: 92px;
      }
      &:nth-child(1) {
        @include mquery(tablet) {
          border-bottom: 1px solid $border_light_grey;
        }
      }
      &:nth-child(2) {
        @include mquery(tablet) {
          border-bottom: 1px solid $border_light_grey;
        }
      }
      &:first-child {
        background-color: transparent;
        width: 30%;
        @include mquery(large) {
          width: 28%;
        }
        @include mquery(tablet) {
          width: 50%;
          border-bottom: 1px solid $border_light_grey;
        }
        p {
          font-size: 18px;
          color: $black_color;
          @include mquery(mobile) {
            font-size: 16px;
            color: #777777;
          }
        }
        h4 {
          font-size: 28px;
          color: $black_color;
          @include mquery(mobile) {
            font-size: 22px;
          }
        }
        &:after {
          display: none;
          @include mquery(tablet) {
            display: block;
          }
        }
      }
      .arabic & {
        padding-left: 22px;
        padding-right: 27px;
      }
      &:after {
        content: "";
        position: absolute;
        right: 0;
        width: 1px;
        height: 68%;
        background-color: $border_light_grey;
        top: 50%;
        @include transform(translateY(-50%));
        .arabic & {
          right: auto;
          left: 0;
        }
      }
      &:last-child {
        border-right: 1px solid $border_light_grey;
        @include mquery(tablet) {
          border-right: none;
        }
        &:after {
          display: none;
        }
      }
      h4 {
        font-size: 20px;
        font-family: $font-base;
        color: $black_color;
        margin-bottom: 0;
        @include mquery(mobile) {
          font-size: 17px;
        }
        .arabic & {
          direction: ltr;
        }
      }
      p {
        font-size: 16px;
        color: #3e3e3e;
        font-family: $font-Futura-Medium;
        margin-bottom: 5px;
      }
    }
  }
}
