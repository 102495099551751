.loyalty_card {
  width: 428px;
  height: 275px;
  color: $font-grey;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 7px 7px 24px rgba(0, 0, 0, 0.25);

  &.arabic {
    .card-top-part {
      .card-info {
        .country-info {
          flex-direction: row-reverse;
        }
      }

      .benefits-info {
        right: auto;
        left: 0;
      }
    }
    .card-bottom-part {
      .loyalty-shops {
        flex-direction: row-reverse;
      }
    }
  }

  .card-top-part {
    height: 60%;
    position: relative;
    padding: 20px;

    &.yellow {
      background: #fed10d;
    }

    &.black {
      background: #1d1e1c;
      color: $white_color;

      .cutoff-date {
        color: inherit;
      }
      .card-info {
        .tier-info {
          color: $yellow_color;
        }

        .country-info {
          color: inherit;
        }
      }
    }

    .card-info {
      display: flex;
      justify-content: space-between;

      .tier-info {
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 2px;
        font-family: $font-base;
      }

      .country-info {
        display: flex;
        align-items: center;
        margin: 0;
        font-size: 14px;
        font-family: 700;

        span {
          width: 20px;
          height: 20px;
          margin: 0 5px;
        }
      }
    }

    .cutoff-date {
      font-size: 18px;
      font-weight: bold;
    }

    .benefits-info {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: inherit;
    }
  }

  .card-bottom-part {
    height: 40%;
    background: $white_color;
    position: relative;
    .bar-code {
      text-align: center;
      direction: ltr;
    }

    .loyalty-shops {
      position: absolute;
      left: 28px;
      bottom: 8px;
      display: flex;

      img {
        margin-right: 7px;
      }
    }
  }

  @include mquery(mobile) {
    width: 350px;
    margin: 0 auto;

    .card-top-part {
      height: 55%;
    }

    .card-bottom-part {
      height: 45%;
    }
  }
}

.card-info-mobile-wrapper {
  font-family: Futura PT;
  padding: 0;
  margin: 20px 0 0 0;
  .card-info-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .tier-info-mobile {
      font-style: normal;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      padding-bottom: 5px;
      margin: 0;
      color: $black_color;
      &.yellow {
        border-bottom: 3px solid #ffd100;
      }
      &.black {
        border-bottom: 3px solid black;
      }
    }
    .country-info-mobile {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      span {
        width: 20px;
        height: 20px;
        margin: 0 5px;
      }
    }
  }
}
