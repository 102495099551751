.loyalty_table_wrapper {
  .no_record_found {
    font-size: 18px;
    color: #ccc;
    text-align: center;
    padding: 30px;
  }
}
.loyalty_table {
  table {
    width: 100%;
    thead {
      th {
        font-size: 16px;
        color: #777;
        padding: 20px 15px 15px;
        line-height: 20px;
        font-family: $font-Futura-Medium;
        font-weight: 500;
        .arabic & {
          text-align: right;
        }
      }
    }
    tbody {
      tr {
        border: 1px solid #dedede;
        border-left: none;
        border-right: none;
        &:nth-child(odd) {
          background-color: $whitef7;
        }
        td {
          color: #000;
          padding: 14px 15px 13px;
          font-size: 16px;
          font-family: $font-base;
          .arabic & {
            text-align: right;
          }
        }
      }
    }
  }
  .transaction_table_mobile {
    .transaction_entry {
      h3 {
        font-size: 16px;
        color: #000;
        font-family: $font-base;
        padding: 14px 11px;
        background-color: $grey_color;
        border-bottom: 1px solid #e3e3e3;
        margin-bottom: 0;
        .arabic & {
          text-align: right;
        }
      }
      .invoice,
      .outlet_name,
      .download-button {
        @include flex;
        padding: 3px 11px;
        p {
          font-size: 16px;
          font-family: $font-base;
          color: #000;
          margin-bottom: 0;
          line-height: 1.31;
          width: calc(100% - 50%);
          &:first-child {
            font-family: $font-Futura-Medium;
            color: #777777;
            width: 36%;
          }
          &:last-child {
            text-align: right;
            width: 35%;
            .arabic & {
              text-align: left;
            }
          }
        }
      }
      .invoice {
        p {
          &:last-child {
            color: $green_color;
          }
        }
      }
      .download-button {
        p {
          display: flex;
          text-decoration: underline;
          cursor: pointer;
          svg {
            margin-right: 5px;
            margin-top: 4px;
            .arabic & {
              margin-right: 0;
              margin-left: 5px;
            }
          }
          &:hover {
            path {
              stroke: $yellow_color;
            }
            color: $yellow_color;
          }
          &:last-child {
            text-align: left;
            .arabic & {
              text-align: right;
            }
          }
        }
        .loader-wrapper {
          .bounce {
            padding: 0;
            width: 45px;
          }
        }
      }
      .date_wise_transaction {
        border-bottom: 1px solid #e3e3e3;
        padding: 7px 0;
        .arabic & {
          text-align: right;
        }

        .no-data {
          padding: 0 11px;
          color: $red_color;
          font-family: $font-Futura-Medium;
          font-size: 14px;
        }
      }
    }
  }
}

.paginate_display {
  .page_count {
    .arabic & {
      padding-right: 0;
      padding-left: 10px;
    }
  }
  .pagination {
    li {
      &:nth-child(2) {
        .arabic & {
          border-left: 1px solid #d6d6d6;
        }
      }
      &:nth-last-child(2) {
        .arabic & {
          border-left: none;
        }
      }
    }
  }
}

.blue_font_btn {
  background-color: transparent;
  color: $sky-blue;
  font-family: $font-Futura-Medium;
  border: none;
  font-size: 16px;
  padding: 14px 15px 13px;
  text-align: center;
  margin: 0 auto;
  display: block;
  width: auto;
  cursor: pointer;
  @include transition(all 0.3s);
  &:hover {
    color: $yellow_color;
  }
}

.positive_transaction_points {
  color: #00a651 !important;
  &:before {
    content: "\002b";
  }
}

.negative_transaction_points {
  color: #f41111 !important;
  &:before {
    //content: '\002d';
  }
}

@include mquery(xsmall) {
  .top_container:has(.bfl-loyalty-program) {
    padding: 0;

    .bfl-loyalty-program {
      .tabordion {
        overflow: hidden;
      }

      .loyalty_table {
        table {
          thead {
            th {
              padding-right: 10px;
              padding-left: 10px;
            }
          }
          tbody {
            tr {
              td {
                padding-right: 10px;
                padding-left: 10px;
                &.lt-title {
                  padding-right: 10px;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }

      ul {
        padding: 0 0 0 20px;
        word-break: break-word;
      }
    }
  }
}
