.loyalty-points-wrapper {
  padding: 8px 19px 19px 15px;
  min-height: 173px;
  font-size: 13px;

  &.arabic {
    .benefits-info {
      text-align: left;
    }
  }

  .loyalty-points-header {
    display: flex;
    justify-content: space-between;

    .card-number-title {
      color: $grey81;
      margin-bottom: 2px;
    }
    .card-number {
      color: $black_color;
      font-size: 16px;
      direction: ltr;
    }
    .points {
      text-align: end;

      p {
        margin-bottom: 0;
      }

      .smile-points {
        font-family: $font-Futura-Medium, Helvetica, Arial, sans-serif;
        font-weight: 900;
        font-size: 26px;
        line-height: 30px;
        margin: 2px 0;
      }
    }

    .bold {
      font-weight: 800;
    }
  }

  .collected-points-info {
    margin-top: 8px;

    &.yellow {
      color: $yellow_color;
    }
  }

  .benefits-info {
    text-align: right;
    a {
      text-decoration: underline;
    }
  }


  &.arabic {
    text-align: right;
  }
}
